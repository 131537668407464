.employees-single .with-submenu.section-leave .ant-col-8.ant-col {
  padding: 1em;
}
.employees-single .employees-single-side {
  position: absolute;
  top: 49px;
  right: 0;
  width: 341.33px;
  padding: 0 30px 16px;
  background-color: var(--sally-submenu-background);
  border-left: 1px solid var(--sally-submenu-border);
  box-shadow: 0px 2px 6px 0px var(--sally-submenu-border);
}
.site-wrapper.with-side-menu .employees-single .employees-single-side {
  top: 0;
  padding-top: 15px;
}

.employees-single .employees-single-side .ant-select {
  width: 100%;
}
.employees-single .employees-single-side .employee-profile {
  position: relative;
  text-align: center;
}
.employees-single .employees-single-side .employee-profile .employee-profile-edit {
  position: absolute;
  top: 35px;
  left: 80%;
}
.employees-single .employees-single-side .employee-profile .employee-profile-edit a {
  display: block;
  width: 40px;
  height: 40px;
  padding: 9px 0 0 2px;
  border: 1px solid #dae1e8;
  border-radius: 50%;
  box-shadow: 0 1px 2px 0 rgba(116, 141, 166, 0.08);
  text-align: center;
}
.employees-single .employees-single-side .employee-profile .employee-profile-image {
  position: relative;
  width: 106px;
  margin: 10px auto;
}
.employees-single .employees-single-side .employee-profile .employee-profile-image .employee-profile-image-hover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
  width: 106px;
  height: 106px;
  padding: 50px 20px 0 20px;
  background-image: url("/src/assets/images/avatar-hover-106x106.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 106px 106px;
  border-radius: 50%;
  line-height: 16px;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
}
.employees-single .employees-single-side .employee-profile .employee-profile-image .ant-upload:hover .employee-profile-image-hover {
  opacity: 1;
}
.employees-single .employees-single-side .employee-profile .employee-profile-image .ant-upload.ant-upload-drag-hover .employee-profile-image-hover {
  opacity: 1;
}
.employees-single .employees-single-side .employee-profile p {
  font-size: 13px;
}
.employees-single .employees-single-side .employee-profile .employee-app {
  padding: 10px 0 0 0;
  text-align: center;
}
.employees-single .employees-single-side .employee-profile .employee-app .employee-app-enabled {
  display: inline-block;
  padding: 0 0 0 25px;
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  font-weight: 500;
  color: var(--sally-green);
  cursor: pointer;
}
.employees-single .employees-single-side .employee-profile .employee-app .ant-btn {
  width: 100%;
  font-size: 15px;
}
.employees-single .employees-single-side .employee-profile:after {
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 20px;
  background-image: linear-gradient(to right, #fff, #dfe2e5, #fff);
}
.employees-single .employees-single-side .employee-details .ant-row, .employees-single .employees-single-side .employee-pay-slips .ant-row {
  line-height: 32px;
  font-size: 14px;
  color: var(--sally-grey-shaded);
}
.employees-single .employees-single-side .employee-details .ant-row + .ant-row, .employees-single .employees-single-side .employee-pay-slips .ant-row + .ant-row {
  margin-top: 0;
}
.employees-single .employees-single-side .employee-details .ant-row > *:first-child, .employees-single .employees-single-side .employee-pay-slips .ant-row > *:first-child {
  font-weight: 500;
  color: var(--sally-black);
}
.employees-single .employees-single-side .employee-details .ant-row > *:last-child, .employees-single .employees-single-side .employee-pay-slips .ant-row > *:last-child {
  overflow-x: hidden;
  text-align: right;
  text-overflow: ellipsis;
}
.employees-single .employees-single-side .employee-details {
  padding: 15px 0 16px 0;
}
.employees-single .employees-single-side .employee-details:not(.employee-last-element):after {
  content: " ";
  display: block;
  height: 1px;
  margin-top: 20px;
  margin-right: -30px;
  margin-left: -30px;
  background-color: #dfe2e5;
}
.employees-single .employees-single-side .employee-switcher {
  padding: 15px 0 14px 0;
}
.employees-single .employees-single-side .employee-switcher .h2 {
  margin-bottom: 10px;
}
.employees-single .employees-single-side .employee-switcher:after {
  content: " ";
  display: block;
  height: 1px;
  margin-top: 32px;
  margin-right: -30px;
  margin-left: -30px;
  background-color: #dfe2e5;
}
.employees-single .employees-single-side .employee-contract-switcher {
  padding: 2px 0 16px 0;
}
.employees-single .employees-single-side .employee-contract-switcher .h2 {
  font-size: 10pt;
  margin-bottom: 0px;
}
.employees-single .employees-single-side .employee-contract-switcher:after {
  content: " ";
  display: block;
  height: 1px;
  margin-top: 32px;
  margin-right: -30px;
  margin-left: -30px;
  background-color: #dfe2e5;
}
.employees-single .employees-single-side .employee-pay-slips {
  padding: 15px 0 14px 0;
}
.employees-single .employees-single-side .employee-pay-slips .h2 {
  margin-bottom: 10px;
}
.employees-single .employees-single-side .employee-pay-slips .icon {
  margin: 0 0 -5px 18px;
}
.employees-single .employees-single-side .employee-pay-slips:after {
  content: " ";
  display: block;
  height: 1px;
  margin-top: 32px;
  margin-right: -30px;
  margin-left: -30px;
  background-color: #dfe2e5;
}
.employees-single .employees-single-side .employee-actions {
  padding: 15px 0 14px 0;
  display: grid;
  grid-template-columns: 50% 50%;
  column-gap: 8px;
  row-gap: 10px;
}
.employees-single .employees-single-side .employee-actions .ant-btn {
  margin: 0;
  width: 100%;
  padding-right: 14px;
  font-size: 15px;
}
.employees-single .employees-single-main {
  max-width: 1240px;
  margin-right: auto;
  margin-left: auto;
  padding: 55px 357.33px 0 0;
  /*
  .ant-table {
      margin: 0 -30px -30px -30px;
      background-color: #fdfdfd;
      border: none;
      border-radius: 0;
      box-shadow: none;

      .ant-table-body {
          > table {
              padding: 0;
          }
          .ant-table-thead {
              > tr > th {
                  padding: 11px 20px;
                  font-size: 14px;
                  font-weight: 400;

                  &:first-child {
                      padding-left: 30px;
                  }
                  &:last-child {
                      padding-right: 30px;
                  }
              }
          }
          .ant-table-tbody {
              > tr > td {
                  padding: 28px 20px;

                  &:first-child {
                      padding-left: 30px;
                  }
                  &:last-child {
                      padding-right: 30px;
                  }
              }
          }
      }
      .employee-table-actions {
          width: 1px;
          white-space: nowrap;
      }
  }*/
  /*
  .ant-btn {
      &.ant-btn-edit, &.ant-btn-view, &.ant-btn-delete {
          min-width: 42px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 16px 16px;
      }
      &.ant-btn-edit {
      }
      &.ant-btn-view {
      }
      &.ant-btn-delete {
          padding-right: 14px;
      }
  }*/
}
.site-wrapper.with-side-menu .employees-single .employees-single-main {
  padding-top: 0;
}

.employees-single .employees-single-main .employees-single-form {
  position: relative;
  margin-bottom: 20px;
}
.employees-single .employees-single-main .employees-single-form .ant-btn.ant-btn-secondary {
  width: auto;
}
.employees-single .employees-single-main .title-menu {
  position: relative;
}
.employees-single .employees-single-main .title-menu .ant-select {
  float: left;
  width: auto;
  margin-top: 0px;
}
.employees-single .employees-single-main .title-menu .ant-select + .ant-btn {
  margin-left: 20px;
}
.employees-single .employees-single-main .title-menu a + a .ant-btn {
  margin-left: 10px;
}
.employees-single .employees-single-main .h1 {
  margin-top: 0;
}
.employees-single .employees-single-main .employees-single-bank-logo {
  padding-top: 3px;
  max-width: 210px;
  max-height: 30px;
}
.employees-single .employees-single-main .ant-spin-nested-loading {
  margin-bottom: 0;
}
.employees-single .employees-single-main .ant-row > * {
  font-size: 14px;
  color: var(--sally-grey-shaded);
}
.employees-single .employees-single-main .ant-row > * strong {
  font-weight: 500;
  color: var(--sally-black);
}
.employees-single .employees-single-main .employees-single-overview-tab .stat-large {
  margin-top: 10px;
  font-size: 35px;
  font-weight: 300;
  color: var(--sally-black);
}
.employees-single .employees-single-main .employees-single-overview-tab .stat-small {
  font-size: 14px;
  font-weight: 400;
  color: var(--sally-grey-shaded);
}
.employees-single .employees-single-main .employees-single-overview-tab .stat-extra {
  font-weight: 500;
  color: var(--sally-green);
}
.employees-single .employees-single-main .employees-single-overview-tab img {
  width: 100%;
  margin-top: 10px;
}
.employees-single .employees-single-main .employees-single-employment-tab .ant-table {
  margin-top: 30px;
}
.employees-single .employees-single-main .employees-single-employment-tab .ant-table span > em {
  font-weight: 400;
  font-style: normal;
  color: var(--sally-grey-shaded);
}
.employees-single .employees-single-main .employees-single-employment-tab .h1 + .ant-table-wrapper .ant-table {
  margin-top: 0;
}
.employees-single .employees-single-main .time-registration-stat {
  margin-bottom: 20px;
}
.employees-single .employees-single-main .time-registration-stat strong {
  display: block;
  font-size: 30px;
  font-weight: 300;
  color: var(--sally-black);
}
.employees-single .employees-single-main .time-registration-stat span {
  display: block;
  margin-top: -3px;
  color: #718294;
}
.employees-single .employees-single-main .time-registration-stat span.time-registration-hours:before, .employees-single .employees-single-main .time-registration-stat span.time-registration-sickness:before, .employees-single .employees-single-main .time-registration-stat span.time-registration-vacation:before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  margin: 0 7px 2px 0;
  border-radius: 50%;
}
.employees-single .employees-single-main .time-registration-stat span.time-registration-hours:before {
  background-color: var(--sally-blue);
}
.employees-single .employees-single-main .time-registration-stat span.time-registration-sickness:before {
  background-color: var(--sally-red);
}
.employees-single .employees-single-main .time-registration-stat span.time-registration-vacation:before {
  background-color: var(--sally-orange);
}
.employees-single .employees-single-main .time-registration-buttons, .employees-single .employees-single-main .salary-registration-buttons {
  text-align: right;
}
.employees-single .employees-single-main .time-registration-buttons .ant-btn, .employees-single .employees-single-main .salary-registration-buttons .ant-btn {
  width: 150px;
}
.employees-single .employees-single-main .time-registration-buttons .ant-btn + .ant-btn, .employees-single .employees-single-main .salary-registration-buttons .ant-btn + .ant-btn {
  margin-top: 10px;
}
.employees-single .employees-single-main .time-registration-buttons .ant-btn.register-time-registration, .employees-single .employees-single-main .salary-registration-buttons .ant-btn.register-time-registration {
  width: 175px;
}
.employees-single .employees-single-main .time-registration-table-approved, .employees-single .employees-single-main .one-time-pay-table-approved, .employees-single .employees-single-main .salary-registration-table-approved {
  width: 1px;
  text-align: center;
  white-space: nowrap;
}
.employees-single .employees-single-main .time-registration-table-approved i, .employees-single .employees-single-main .one-time-pay-table-approved i, .employees-single .employees-single-main .salary-registration-table-approved i {
  background-position-y: 5px;
}
.employees-single.section-documents .employees-single-main {
  max-width: 1500px;
}
@media (min-width: 1200px) {
  .employees-single .employees-single-menu {
    right: 416px;
  }
  .employees-single .employees-single-side {
    width: 416px;
  }
  .site-wrapper:not(.with-side-menu) .employees-single .employees-single-side .employee-profile .employee-profile-edit {
    top: 25px;
  }
  .site-wrapper:not(.with-side-menu) .employees-single .employees-single-side .employee-profile .employee-profile-image {
    margin-top: -10px;
  }

  .employees-single .employees-single-main {
    padding-right: 432px;
  }
}

.ant-table .table-overflow-ellipsis {
  display: inline-block;
  max-width: 200px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  word-break: keep-all;
  white-space: nowrap;
}
.ant-table .table-overflow-ellipsis + span.document-signed-note {
  position: relative;
  bottom: 5px;
}
.ant-table .table-approve-wrapper {
  display: flex;
  justify-content: center;
}

.employment-buttons {
  text-align: right;
}
.employment-buttons .ant-btn {
  min-width: 100px;
  margin-left: 20px;
  padding-right: 14px;
}

.ready-buttons {
  text-align: left;
}
.ready-buttons .ant-btn {
  min-width: 100px;
  margin-right: 20px;
  padding-right: 14px;
}

.employees-car-allowance-map {
  position: relative;
  overflow-y: hidden;
  width: 440px;
  height: 290px;
  border: 1px solid #dae1e8;
  border-radius: 4px;
}
.employees-car-allowance-map .employees-car-allowance-map-inner {
  width: 440px;
  height: 340px;
  margin-top: -25px;
}
.employees-car-allowance-map:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  opacity: 0;
}

.employees-car-allowance-warning {
  width: 440px;
  margin-top: 20px;
  margin-bottom: 0;
}

.ant-card.ant-card-bordered.no-contract-card .ant-card-body, .ant-card.ant-card-bordered.no-employment-card .ant-card-body {
  background-position: 30px center;
  background-repeat: no-repeat;
  background-size: 40px 40px;
}
.ant-card.ant-card-bordered.no-contract-card .ant-card-body p, .ant-card.ant-card-bordered.no-employment-card .ant-card-body p {
  margin: 0;
  font-size: 14px;
  color: var(--sally-grey-shaded);
}
.ant-card.ant-card-bordered.no-contract-card .ant-card-body a, .ant-card.ant-card-bordered.no-employment-card .ant-card-body a {
  position: absolute;
  top: 50%;
  right: 40px;
  display: inline-block;
  line-height: 20px;
  margin-top: -10px;
  padding-right: 15px;
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 12px 12px;
  font-size: 14px;
  font-weight: 500;
}
.ant-card.ant-card-bordered.no-contract-card .ant-card-body, .ant-card.ant-card-bordered.no-contract-card:hover .ant-card-body, .ant-card.ant-card-bordered.no-employment-card .ant-card-body, .ant-card.ant-card-bordered.no-employment-card:hover .ant-card-body {
  padding: 25px 110px 25px 90px;
}

.employees-terminate .ant-card.ant-card-bordered.employees-terminate-profile {
  margin: 20px 0;
}
.employees-terminate .ant-card.ant-card-bordered.employees-terminate-profile .ant-card-body {
  padding: 20px 24px;
}
.employees-terminate .ant-card.ant-card-bordered.employees-terminate-profile .ant-card-body .user-image {
  float: left;
  display: block;
  margin: 4px 15px 0 0;
}
.employees-terminate .ant-card.ant-card-bordered.employees-terminate-profile .ant-card-body .employees-terminate-profile-data {
  float: left;
}
.employees-terminate .ant-card.ant-card-bordered.employees-terminate-profile .ant-card-body .employees-terminate-profile-data p {
  margin: 0;
  font-size: 13px;
  color: var(--sally-grey-shaded);
}
.employees-terminate .ant-card.ant-card-bordered.employees-terminate-profile .ant-card-body:after {
  content: " ";
  display: block;
  clear: left;
}

.employees-single .employees-single-actions {
  float: right;
  margin-top: 7px;
}

.employees-single .employees-single-overview {
  margin-top: 20px;
}

.employees-single .employees-single-action-button {
  float: right;
  text-align: right;
}

.employees-single .employees-single-action-button > a {
  display: block;
}

.employees-single .employees-single-action-button > a + a {
  margin-top: 5px;
}

.employees-single .employees-single-calendar .events {
  line-height: 18px;
  list-style: none;
  margin: 0;
  padding: 0;
}

.employees-single .employees-single-calendar .events li {
  color: #999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.employees-single .employees-single-calendar .events li span {
  vertical-align: middle;
}

.employees-single .employees-single-calendar .events li span:first-child {
  font-size: 9px;
  margin-right: 4px;
}

.employees-single .employees-single-calendar .event-warning {
  color: #fac450;
}

.employees-single .employees-single-calendar .event-normal {
  color: var(--sally-blue);
}

.employees-single .employees-single-calendar .event-error {
  color: #f50;
}

.employees-single .employees-single-calendar .notes-month {
  text-align: center;
}

.employees-single .employees-single-calendar .notes-month div {
  float: left;
  width: 50%;
}

.employees-single .employees-single-calendar .notes-month div section {
  font-size: 28px;
}

.employees-single-table .employees-single-table-number-cell {
  text-align: right;
}

.employees-single .employees-single-table .employees-single-table-action-cell {
  width: 1px;
  white-space: nowrap;
}

.employee-contract-switcher-item.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item, .contract-history-item.ant-select-dropdown-menu-item {
  border-color: inherit;
}
.employee-contract-switcher-item.ant-select-dropdown-menu-item.expired, .contract-history-item.expired {
  background: var(--sally-grey-faded);
}
.employee-contract-switcher-item.ant-select-dropdown-menu-item.expired.contract-history-item, .contract-history-item.expired.contract-history-item {
  border-color: var(--sally-grey-shaded);
}
.employee-contract-switcher-item.ant-select-dropdown-menu-item.expired.ant-select-dropdown-menu-item-active, .contract-history-item.expired.ant-select-dropdown-menu-item-active {
  background: #a3a3a3;
}
.employee-contract-switcher-item.ant-select-dropdown-menu-item.active, .contract-history-item.active {
  background: var(--sally-green-faded);
}
.employee-contract-switcher-item.ant-select-dropdown-menu-item.active.contract-history-item, .contract-history-item.active.contract-history-item {
  border-color: var(--sally-green-shaded);
}
.employee-contract-switcher-item.ant-select-dropdown-menu-item.active.ant-select-dropdown-menu-item-active, .contract-history-item.active.ant-select-dropdown-menu-item-active {
  background: var(--sally-green);
}
.employee-contract-switcher-item.ant-select-dropdown-menu-item.future, .contract-history-item.future {
  background: var(--sally-orange-faded);
}
.employee-contract-switcher-item.ant-select-dropdown-menu-item.future.contract-history-item, .contract-history-item.future.contract-history-item {
  border-color: var(--sally-orange-shaded);
}
.employee-contract-switcher-item.ant-select-dropdown-menu-item.future.ant-select-dropdown-menu-item-active, .contract-history-item.future.ant-select-dropdown-menu-item-active {
  background: var(--sally-orange);
}

.contract-history-item {
  border: 1px solid var(--sally-grey-shaded);
  border-radius: 3px;
  padding: 12px 0px;
}
.contract-history-item.ant-row.expired > *, .contract-history-item.ant-row.active > *, .contract-history-item.ant-row.future > * {
  color: var(--sally-black);
}
.contract-history-item .contract-change {
  display: block;
}
.contract-history-item .contract-change.increase {
  color: var(--sally-green-shaded);
}
.contract-history-item .contract-change.decrease, .contract-history-item .contract-change.remove {
  color: var(--sally-red-shaded);
}
.contract-history-item .contract-no-change {
  font-style: italic;
}
.contract-history-item .contract-change-line {
  display: block;
  font-size: 9pt;
  line-height: 12pt;
  margin-top: -2px;
  color: var(--sally-black-faded);
}
.contract-history-item .ant-btn {
  float: right;
}

body .ant-btn.ant-btn-sm.ant-btn-unlock,
body .ant-btn.ant-btn-sm.ant-btn-lookup {
  margin-top: 24px;
  height: 38px;
  width: 100%;
  background-color: var(--sally-white-faded);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
body .ant-btn.ant-btn-sm.ant-btn-unlock.ant-btn-unlock.unlocked,
body .ant-btn.ant-btn-sm.ant-btn-lookup.ant-btn-unlock.unlocked {
  background-color: var(--sally-white);
}
body .ant-btn.ant-btn-sm.ant-btn-unlock.ant-btn-lookup,
body .ant-btn.ant-btn-sm.ant-btn-lookup.ant-btn-lookup {
  background-color: var(--sally-white);
}
body .ant-btn.ant-btn-sm.ant-btn-unlock[disabled],
body .ant-btn.ant-btn-sm.ant-btn-lookup[disabled] {
  background-color: var(--sally-white-faded);
}

.ant-card .h1 .subtitle-link {
  display: block;
  font-size: 10pt;
  margin-top: -10px;
}

body .created-at-note {
  font-size: 8pt;
  font-style: italic;
  color: var(--sally-grey);
}

