body .employees-work-hours-tab {

  .work-week-detailed{
    .title-menu{
      display: flex;
    }
  }
  .work-week-simple{
    .title-menu{
      display: flex;
      align-items: center;
    }
  }

  .work-hours-week-form-button{
    margin-top: 3em;
  }
  .work-hours-week-form {
    .work-hours-week-form-hours-week {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .work-hours-week-form-hours-day {
        flex: 1 1 0;
      }
    }
    .work-hours-week-form-week-day {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: baseline;

      .work-hours-week-form-leave-note {
        font-size: 8pt;
        font-style: italic;
      }
    }
  }
  .work-week-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .work-week-summary {
    text-align: center;
    font-size: 13pt;
    color: var(--sally-grey-shaded);
    padding: 1.2em;

    span {
      font-weight: bold;
    }
  }
  .work-week-template {
    margin-bottom: 10px;
  }
  .work-week-detailed-button {
    text-align: right;
      margin-left: 1em;
  }
  .ant-row .text-cell {
    padding-top: 8px;
  }
  .employees-work-hours-tab-violation-message {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    p {
      color: var(--sally-white);
    }
  }
}